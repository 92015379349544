/* eslint-disable no-nested-ternary */
/* eslint-disable no-constant-condition */
import React from 'react';
import TeacherDashboardLayout from 'version-two/layout/TeacherDashboardLayout';
import {
  TabPanel,
  TabPanels,
  Tabs,
  // useBreakpointValue,
} from '@chakra-ui/react';
// import AppText from 'version-two/components/Typography/AppText';
// import { CopyContentIcon, ManageLockIcon } from 'version-two/assets';
// import GroupUserProfileImage from 'version-two/components/GroupUserProfileImage/GroupUserProfileImage';
// import TeacherProgramDetailDrawable from 'version-two/components/TeacherProgramDetailDrawable/TeacherProgramDetailDrawable';
// import ManageModuleAccessBulk from 'version-two/components/ManageModuleAccessBulk/ManageModuleAccessBulk';
import { useQuery } from 'react-query';
import {
  GET_TEACHER_ID_MODULE,
  TEACHER_BATCH_VERSION,
} from 'version-two/api/urls/teacher';
import makeGetRequest from 'version-two/api/utils/makeGetRequest';
import AppLoader from 'version-two/components/AppLoader/AppLoader';
import { useParams } from 'react-router-dom';
// import { useEffect, useState } from 'react';
// import AppFlex from 'version-two/components/chakraLayoutOverrides/AppFlex';
// import { Select } from 'chakra-react-select';
// import useStudentSearch from 'version-two/hooks/useStudentSearch';
import {
  MAKE_TEACHER_PROGRAM_DETAIL,
  TEACHER_DASHBOARD,
} from 'version-two/navigation/routes';
// import appColors from 'version-two/theme/appColors';
import TeacherCourseMaterial from 'version-two/components/TeacherTabs/TeacherCourseMaterial';
import TeacherScheduleTab from 'version-two/components/TeacherTabs/TeacherScheduleTab';
import TeacherSessionFlow from 'version-two/components/TeacherTabs/TeacherSessionFlow';
import TeacherZoomDetails from 'version-two/components/TeacherTabs/TeacherZoomDetails';
import ActiveStudentsTab from 'version-two/components/TeacherTabs/ActiveStudentsTab';
import TeacherClassDetail from 'version-two/components/TeacherTabs/TeacherClassDetail';
import TeacherSchedule from 'version-two/components/TeacherTabs/TeacherSchedule';
// import TeacherSchedule from 'version-two/components/TeacherTabs/TeacherSchedule';

function TeacherProgramDetail() {
  const [tabIndex, setTabIndex] = React.useState(0);
  const [classDetails, setClassDetails] = React.useState(null);
  // const navigate = useNavigate();

  const { programSlug } = useParams();

  const { data: programDetail, isLoading: programDetailIsLoading } = useQuery(
    GET_TEACHER_ID_MODULE(programSlug),
    () => makeGetRequest(GET_TEACHER_ID_MODULE(programSlug)),
  );

  const { data: batchDetail, isLoading: batchIsLoading } = useQuery(
    TEACHER_BATCH_VERSION(programSlug),
    () => makeGetRequest(TEACHER_BATCH_VERSION(programSlug)),
  );

  // const isMobile = useBreakpointValue({ base: true, sm: false });

  // const [activeModule, setActiveModule] = useState();

  // const {
  //   isOpen: programDrawableIsOpen,
  //   onOpen: programDrawableOnOpen,
  //   onClose: programDrawableOnClose,
  // } = useDisclosure();

  // const {
  //   isOpen: moduleManageIsOpen,
  //   onOpen: moduleManageOnOpen,
  //   onClose: moduleManageOnClose,
  // } = useDisclosure();

  // const handleManageClick = (module) => {
  //   setActiveModule(module);
  //   moduleManageOnOpen();
  // };

  // const handleManageClose = () => {
  //   moduleManageOnClose();
  // };

  // const handleNavigateToDrawable = (module) => {
  //   navigate(MAKE_TEACHER_PROGRAM_DETAIL_CONTENT(programSlug, module.id));
  // };

  // const handleContentOpen = (module) => {
  //   setActiveModule(module);
  //   programDrawableOnOpen();
  // };

  // const handleDrawableClose = () => {
  //   setActiveModule(null);
  //   programDrawableOnClose();
  // };
  // const handleContentClose = () => {
  //   navigate(MAKE_TEACHER_PROGRAM_DETAIL(programSlug));
  //   handleDrawableClose();
  // };

  // const { searchDefaultProps } = useStudentSearch({ programSlug });

  // useEffect(() => {
  //   if (programDetail && moduleSlug) {
  //     programDetail.data.modules.some((programData) => {
  //       if (programData.id === moduleSlug) {
  //         handleContentOpen(programData);
  //         console.log('matched');
  //         return true;
  //       }
  //       return false;
  //     });
  //   }

  //   if (!moduleSlug) {
  //     handleDrawableClose();
  //   }
  // }, [moduleSlug, programDetail]);

  const pageBreadCrumbDetail = [
    {
      name: 'Your Batches',
      link: TEACHER_DASHBOARD,
    },
    {
      name:
        programDetail?.data?.batch?.week_day &&
        programDetail?.data?.batch?.batch_timings
          ? `${programDetail?.data.batch.week_day}s ${
              programDetail?.data.batch.batch_timings.split('I')[0]
            }`
          : '',
      link: MAKE_TEACHER_PROGRAM_DETAIL(programSlug),
    },
    ...(classDetails
      ? [
          {
            name: classDetails.name || '',
            link: MAKE_TEACHER_PROGRAM_DETAIL(programSlug),
          },
        ]
      : []),
  ];

  const handleTabsChange = (index) => {
    setTabIndex(index);
    setClassDetails(null);
  };

  return (
    <Tabs variant="teacherTab" index={tabIndex} onChange={handleTabsChange}>
      <TeacherDashboardLayout
        tabIndex={tabIndex}
        isProgram
        breadCrumb={pageBreadCrumbDetail}
        setClassDetails={setClassDetails}
      >
        <AppLoader isLoading={programDetailIsLoading || batchIsLoading}>
          <TabPanels>
            <TabPanel padding="0px">
              <TeacherCourseMaterial />
            </TabPanel>
            <TabPanel padding="0px">
              <TeacherSessionFlow />
            </TabPanel>
            <TabPanel padding="0px">
              {!classDetails ? (
                batchDetail?.data?.api === 'new' ? (
                  <TeacherScheduleTab
                    classDetails={classDetails}
                    setClassDetails={setClassDetails}
                  />
                ) : (
                  <TeacherSchedule />
                )
              ) : (
                <TeacherClassDetail
                  classDetails={classDetails}
                  programDetail={programDetail}
                />
              )}
            </TabPanel>
            <TabPanel padding="0px">
              <TeacherZoomDetails />
            </TabPanel>
            <TabPanel padding="0px">
              <ActiveStudentsTab
                BatchVersion={batchDetail?.data?.api}
                BatchTitle={programDetail?.data?.batch?.identity}
              />
            </TabPanel>
          </TabPanels>
        </AppLoader>
      </TeacherDashboardLayout>
    </Tabs>
  );
}

export default TeacherProgramDetail;
